import Parse from "parse/dist/parse.min.js";

// Full Calendar Plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from "@vue/composition-api";
import store from "@/store";

import moment from "moment";

export default function userCalendar() {
  // Use toast
  const toast = useToast();
  // ------------------------------------------------
  // refCalendar
  // ------------------------------------------------
  const refCalendar = ref(null);

  // ------------------------------------------------
  // calendarApi
  // ------------------------------------------------
  let calendarApi = null;
  onMounted(() => {
    calendarApi = refCalendar.value.getApi();
  });

  // ------------------------------------------------
  // calendars
  // ------------------------------------------------
  const calendarsColor = {
    "For Review": "primary",
    Approved: "success",
    Rejected: "danger",
  };

  // ------------------------------------------------
  // event
  // ------------------------------------------------
  const blankEvent = {
    title: "",
    start: "",
    end: "",
    allDay: false,
    url: "",
    extendedProps: {
      calendar: "",
      guests: [],
      location: "",
      description: "",
      assistant: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      intra: false,
      status: -1,
      rejectReason: "",
    },
  };
  const event = ref(JSON.parse(JSON.stringify(blankEvent)));
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent));
  };

  // *===========================================================================---*
  // *--------- Calendar API Function/Utils --------------------------------------------*
  // Template Future Update: We might move this utils function in its own file
  // *===========================================================================---*

  // ------------------------------------------------
  // (UI) addEventInCalendar
  // ? This is useless because this just add event in calendar and not in our data
  // * If we try to call it on new event then callback & try to toggle from calendar we get two events => One from UI and one from data
  // ------------------------------------------------
  // const addEventInCalendar = eventData => {
  //   toast({
  //     component: ToastificationContent,
  //     position: 'bottom-right',
  //     props: {
  //       title: 'Event Added',
  //       icon: 'CheckIcon',
  //       variant: 'success',
  //     },
  //   })
  //   calendarApi.addEvent(eventData)
  // }

  // ------------------------------------------------
  // (UI) updateEventInCalendar
  // ------------------------------------------------
  const updateEventInCalendar = (
    updatedEventData,
    propsToUpdate,
    extendedPropsToUpdate
  ) => {
    toast({
      component: ToastificationContent,
      props: {
        title: "Event Updated",
        icon: "CheckIcon",
        variant: "success",
      },
    });

    const existingEvent = calendarApi.getEventById(updatedEventData.id);

    // --- Set event properties except date related ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setProp
    // dateRelatedProps => ['start', 'end', 'allDay']
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < propsToUpdate.length; index++) {
      const propName = propsToUpdate[index];
      existingEvent.setProp(propName, updatedEventData[propName]);
    }

    // --- Set date related props ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setDates
    existingEvent.setDates(updatedEventData.start, updatedEventData.end, {
      allDay: updatedEventData.allDay,
    });

    // --- Set event's extendedProps ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setExtendedProp
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < extendedPropsToUpdate.length; index++) {
      const propName = extendedPropsToUpdate[index];
      existingEvent.setExtendedProp(
        propName,
        updatedEventData.extendedProps[propName]
      );
    }
  };

  // ------------------------------------------------
  // (UI) removeEventInCalendar
  // ------------------------------------------------
  const removeEventInCalendar = (eventId) => {
    toast({
      component: ToastificationContent,
      props: {
        title: "Event Removed",
        icon: "TrashIcon",
        variant: "danger",
      },
    });
    calendarApi.getEventById(eventId).remove();
  };

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
  // ! You need to update below function as per your extendedProps
  // ------------------------------------------------
  const grabEventDataFromEventApi = (eventApi) => {
    const {
      id,
      title,
      start,
      end,
      // eslint-disable-next-line object-curly-newline
      extendedProps: {
        calendar,
        guests,
        location,
        description,
        assistant,
        startDate,
        endDate,
        startTime,
        endTime,
        intraday,
        status,
        rejectReason,
      },
      allDay,
    } = eventApi;

    return {
      id,
      title,
      start,
      end,
      extendedProps: {
        calendar,
        guests,
        location,
        description,
        assistant,
        startDate,
        endDate,
        startTime,
        endTime,
        intraday,
        status,
        rejectReason,
      },
      allDay,
    };
  };

  // ------------------------------------------------
  // addEvent
  // ------------------------------------------------
  const addEvent = (eventData) => {
    const TimeOff = Parse.Object.extend("TimeOff");
    const Assitant = Parse.Object.extend("_User");

    const timeOff = new TimeOff();

    const currentUser = Parse.User.current();
    const assistant = new Assitant();
    assistant.id = currentUser.id;

    timeOff.set("assistant", assistant);
    timeOff.set("startDate", new Date(eventData.extendedProps.startDate));
    timeOff.set("intraday", eventData.extendedProps.intraday);
    timeOff.set("status", eventData.extendedProps.status);
    if (eventData.extendedProps.intraday) {
      timeOff.set(
        "startTime",
        eventData.extendedProps.startTime.replaceAll("m", "0")
      );
      timeOff.set(
        "endTime",
        eventData.extendedProps.endTime.replaceAll("m", "0")
      );
    } else {
      timeOff.set("endTime", eventData.extendedProps.endTime);
    }

    timeOff.save().then(
      (timeOff) => {
        // Execute any logic that should take place after the object is saved.
        refetchEvents();
      },
      (error) => {
        // Execute any logic that should take place if the save fails.
        // error is a Parse.Error with an error code and message.
        toast({
          component: ToastificationContent,
          props: {
            title: "Failed to create timeoff",
            icon: "TrashIcon",
            variant: "danger",
          },
        });
      }
    );
  };

  // ------------------------------------------------
  // updateEvent
  // ------------------------------------------------
  const updateEvent = (eventData) => {
    const TimeOff = Parse.Object.extend("TimeOff");
    const timeOff = new TimeOff();

    timeOff.set("id", eventData.id);
    timeOff.set("startDate", new Date(eventData.extendedProps.startDate));
    timeOff.set("intraday", eventData.extendedProps.intraday);
    timeOff.set("status", eventData.extendedProps.status);
    timeOff.set("rejectReason", eventData.extendedProps.rejectReason);

    if (eventData.extendedProps.intraday) {
      timeOff.set(
        "startTime",
        eventData.extendedProps.startTime.replaceAll("m", "0")
      );
      timeOff.set(
        "endTime",
        eventData.extendedProps.endTime.replaceAll("m", "0")
      );
    } else {
      timeOff.set("endTime", eventData.extendedProps.endTime);
    }

    timeOff.save().then(
      (result) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Event Updated",
            icon: "CheckIcon",
            variant: "success",
          },
        });
        refetchEvents();
      },
      (error) => {
        // Execute any logic that should take place if the save fails.
        // error is a Parse.Error with an error code and message.
        toast({
          component: ToastificationContent,
          props: {
            title: "Failed to create timeoff",
            icon: "TrashIcon",
            variant: "danger",
          },
        });
      }
    );
  };

  // ------------------------------------------------
  // removeEvent
  // ------------------------------------------------
  const removeEvent = () => {
    const eventId = event.value.id;
    store.dispatch("calendar/removeEvent", { id: eventId }).then(() => {
      removeEventInCalendar(eventId);
    });
  };

  // ------------------------------------------------
  // refetchEvents
  // ------------------------------------------------
  const refetchEvents = () => {
    calendarApi.refetchEvents();
  };

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedCalendars = computed(
    () => store.state.calendar.selectedCalendars
  );

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const allCalendarOptions = computed(
    () => store.state.calendar.calendarOptions
  );

  watch(selectedCalendars, () => {
    refetchEvents();
  });

  // --------------------------------------------------------------------------------------------------
  // AXIOS: fetchEvents
  // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
  // --------------------------------------------------------------------------------------------------
  const fetchEvents = (info, successCallback) => {
    // If there's no info => Don't make useless API call
    if (!info) return;
    const TimeOff = Parse.Object.extend("TimeOff");
    const query = new Parse.Query(TimeOff);
    const currentUser = Parse.User.current();

    const Assitant = Parse.Object.extend("_User");
    const assistant = new Assitant();
    assistant.id = currentUser.id;
    query.equalTo("assistant", assistant);

    query.greaterThanOrEqualTo("startDate", info.start);
    query.lessThanOrEqualTo("startDate", info.end);

    query
      .find()
      .then((results) => {
        successCallback(
          results
            // .filter((result) =>
            //   selectedCalendars.value.includes(
            //     result.attributes.status === -1
            //       ? "For Review"
            //       : result.attributes.status === 0
            //       ? "Rejected"
            //       : "Approved"
            //   )
            // )
            .map((result) => {
              let startDate = new Date(result.attributes.startDate);
              let endDate = new Date(result.attributes.endDate);

              if (result.attributes.intraday) {
                endDate = new Date(result.attributes.startDate);
                const startTimeComponents =
                  result.attributes.startTime.split(":");
                startDate.setHours(
                  parseInt(startTimeComponents[0]),
                  parseInt(startTimeComponents[1]),
                  0
                );
                const endTimeComponents = result.attributes.endTime.split(":");
                endDate.setHours(
                  parseInt(endTimeComponents[0]),
                  parseInt(endTimeComponents[1]),
                  0
                );
              } else {
                startDate.setHours(0, 0, 0);
                endDate.setHours(23, 59, 59);
              }

              return {
                title: `${result.attributes.assistant.attributes.firstName} ${result.attributes.assistant.attributes.lastName}`,
                start: startDate,
                end: endDate,
                extendedProps: {
                  description: ``,
                  assistant: result.attributes.assistant.id,
                  startDate: result.attributes.startDate,
                  endDate: result.attributes.endDate,
                  startTime: result.attributes.startTime,
                  endTime: result.attributes.endTime,
                  intraday: result.attributes.intraday,
                  date: result.attributes.date,
                  status: result.attributes.status,
                  rejectReason: result.attributes.rejectReason,
                  calendar:
                    result.attributes.status === -1
                      ? "For Review"
                      : result.attributes.status === 0
                      ? "Rejected"
                      : "Approved",
                },
                id: result.id,
              };
            })
        );
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching bookings",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of calendar app
  // ------------------------------------------------------------------------
  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: "timeGridWeek",
    headerToolbar: {
      start: "sidebarToggle, prev,next, title",
      end: "timeGridWeek,listMonth",
    },
    events: fetchEvents,

    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: true,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 2,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      const colorName =
        calendarsColor[calendarEvent._def.extendedProps.calendar];

      return [
        // Background Color
        `bg-light-${colorName}`,
      ];
    },
    eventClick({ event: clickedEvent }) {
      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      event.value = grabEventDataFromEventApi(clickedEvent);
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true;
    },

    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: "sidebar",
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value =
            !isCalendarOverlaySidebarActive.value;
        },
      },
    },

    dateClick(info) {
      /*
        ! Vue3 Change
        Using Vue.set isn't working for now so we will try to check reactivity in Vue 3 as it can handle this automatically
        ```
        event.value.start = info.date
        ```
      */
      event.value = JSON.parse(
        JSON.stringify(Object.assign(event.value, { start: info.date }))
      );
      event.value.extendedProps.startDate = moment(info.date).format(
        "YYYY-MM-DD"
      );

      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true;
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }) {
      updateEvent(grabEventDataFromEventApi(droppedEvent));
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }) {
      updateEvent(grabEventDataFromEventApi(resizedEvent));
    },

    // Get direction from app state (store)
    direction: computed(() => (store.state.appConfig.isRTL ? "rtl" : "ltr")),
    rerenderDelay: 350,
    eventTimeFormat: {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    },
    firstDay: 1,
    slotLabelFormat: {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    },
    dayHeaderFormat: { day: "numeric", weekday: "short", omitCommas: true },
  });

  // ------------------------------------------------------------------------

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const isEventHandlerSidebarActive = ref(false);

  const isCalendarOverlaySidebarActive = ref(false);

  return {
    refCalendar,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    event,
    clearEventData,
    addEvent,
    updateEvent,
    removeEvent,
    refetchEvents,
    fetchEvents,

    // ----- UI ----- //
    isEventHandlerSidebarActive,
  };
}
